import React from 'react';
import styled from 'styled-components';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Accordion } from '@latitude/accordion';
import RatesListBranded from '../RatesList/RatesListBranded';
import { Box } from '../Box/Box';
import { Heading3 } from '../Heading/Heading';
import { FONT_FAMILY, BREAKPOINT } from '../../utils/constants';

const RatesAndFees = props => {
  const heading = 'Rates, Fees and Conditions';

  return (
    <AnalyticsLocationProvider location={heading}>
      <Outer id={props.id} className={props.className}>
        <Inner isResponsive>
          <Title align="center">{heading}</Title>
          <RatesListBranded {...props.ratesListProps} />
          <AccordionContainer>
            <AccordionStyled
              titleBgColor="grey-light"
              {...props.accordionProps}
            />
          </AccordionContainer>
        </Inner>
      </Outer>
    </AnalyticsLocationProvider>
  );
};

const Outer = styled(Box)`
  background-color: #f8f8f8;
  padding: 40px 15px;
  overflow: hidden;

  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 72px 0;
  }
`;

const Inner = styled(Box)`
  margin: 0 auto;
  padding: 0;
`;

const Title = styled(Heading3)`
  text-align: center;
  font-family: ${FONT_FAMILY.TITLE};
  font-weight: 600;
  font-size: 24px;
  color: #000;
  margin-bottom: 24px;

  @media (min-width: ${BREAKPOINT.LG}) {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 48px;
  }
`;

const AccordionContainer = styled.div`
  max-width: 800px;
  margin: 8px auto 0;
`;

const AccordionStyled = styled(Accordion)`
  .accordion-custom .accordion-item__heading {
    background-color: #f3f3f3;
  }
  .accordion-custom .accordion-item__body {
    padding: 0;
  }
  .accordion-custom .accordion__row {
    padding: 5px 30px;
    background: white;
    border-bottom: 1px solid #dae3e7;
  }
  .accordion-custom .accordion__row:last-child {
    border-bottom: 0;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-top: 48px;
  }

  .accordion__item button.link-block {
    :focus {
      outline-color: #ddd;
    }
  }
`;

export default RatesAndFees;
