import styled from 'styled-components';
import RatesList from './RatesList';
import './_rates-list.scss';
import { FONT_FAMILY, BREAKPOINT } from '../../utils/constants';

const RatesListBranded = styled(RatesList)`
  position: relative;

  @media (min-width: ${BREAKPOINT.LG}) {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: -30px;
      left: 0;
      display: block;
      width: 54px;
      height: 79px;
      background-image: url(${require('../../images/placeholders/rateslist-confetti.png')
        .default});
      background-size: contain;
      transform: translateX(calc(-100% - 1px));
      pointer-events: none;
    }

    &:after {
      left: auto;
      right: 0;
      transform: rotate(180deg) translateX(calc(-100% - 1px));
    }
  }

  .rates-list-item__large-text,
  .rates-list-item__extras {
    font-family: ${FONT_FAMILY.TITLE};
    font-weight: 600;
    color: #0046aa;
  }

  .rates-list-item__small-text {
    color: #000;
  }

  .rates-list-item,
  .rates-list-item:last-child {
    border-width: 1px;
    border-color: #aba7f6;
  }
`;

export default RatesListBranded;
